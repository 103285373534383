










































































































































































































import { ConfiguracaoDeCupom } from '@/models'
import { criarConfiguracaoDeCupom } from '@/shareds/venda-shareds'
import { Vue, Component, PropSync, Ref, Watch } from 'vue-property-decorator'
import { maiorOuIgualAZero, maiorQueZero, obrigatorio } from '@/shareds/regras-de-form'
import RangeDatePicker from '../ui/RangeDatePicker.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveConfiguracaoDeCupomUseCase } from '@/usecases'
import CampoDecimal from '../ui/CampoDecimal.vue'
import RangerTimePicker from '../ui/RangerTimePicker.vue'
import SeletorDeGruposEconomicos from '../loja/SeletorDeGruposEconomicos.vue'
import SeletorDeLojas from '../loja/SeletorDeLojas.vue'

@Component({
	components: {
		RangeDatePicker,
		CampoDecimal,
		RangerTimePicker,
		SeletorDeGruposEconomicos,
		SeletorDeLojas,
	},
})
export default class DialogoDeEdicaoDeConfiguracaoDeCupons extends Vue {
	@PropSync('value', { type: Object, default: null }) configuracaoDeCupom?: ConfiguracaoDeCupom
	@Ref() formDeEdicaoDeConfiguracaoDeCupons!: HTMLFormElement
	@Ref() campoDeDesconto!: HTMLInputElement

	saveConfiguracaoDeCupomUseCase = new SaveConfiguracaoDeCupomUseCase()
	configuracaoDeCupomDoFormulario: ConfiguracaoDeCupom = criarConfiguracaoDeCupom()

	mostraDialogo = false
	formValid = false
	carregando = false
	mostraMenu = false
	datasDeVigencia: string[] = []
	horas = ''

	@Ref() menu!: {
		save: (value?: any) => void
	}

	obrigatorio = obrigatorio
	maiorQueZero = maiorQueZero
	maiorOuIgualAZero = maiorOuIgualAZero

	@Watch('mostraDialogo', { immediate: true })
	onChangeHora() {
		if(!this.mostraDialogo) return
		const configuracaoDeCupom = this.configuracaoDeCupom
		const dataInicioVigencia = configuracaoDeCupom && configuracaoDeCupom.dataInicioVigencia 
			?  new Date(configuracaoDeCupom.dataInicioVigencia)
			:  new Date();
		
		const hora = dataInicioVigencia.getHours().toString().padStart(2, '0');
		const min = dataInicioVigencia.getMinutes().toString().padStart(2, '0');
		
		this.horas = `${hora}:${min}`;
	}


	@Watch('configuracaoDeCupom', { immediate: true })
	onChangeCupom(configuracaoDeCupom: ConfiguracaoDeCupom) {
		if (!configuracaoDeCupom) return;

		const { dataInicioVigencia, dataFinalVigencia } = configuracaoDeCupom;
		if (!dataInicioVigencia || !dataFinalVigencia) return;

		const [inicio, final] = [dataInicioVigencia as string, dataFinalVigencia as string].map(date => date.split("T")[0]);
		this.datasDeVigencia = [inicio, final];

		this.configuracaoDeCupomDoFormulario = configuracaoDeCupom
			? JSON.parse(JSON.stringify(configuracaoDeCupom))
			: criarConfiguracaoDeCupom();
	}


	definirHoraParaData(data: any, horaString: any) {
		if (data) {
			const [hora, minutos] = horaString.split(':');
			data.setHours(Number(hora), Number(minutos));
		}
	}

	async salvarCupom() {
		if (!this.configuracaoDeCupomDoFormulario) return
		if (!this.formDeEdicaoDeConfiguracaoDeCupons.validate()) return
		this.carregando = true
		try {
			this.configuracaoDeCupomDoFormulario.dataInicioVigencia = this.datasDeVigencia[0] && this.datasDeVigencia[1]
				? new Date(this.datasDeVigencia[0] + ' 00:00:00.000') : null
			this.configuracaoDeCupomDoFormulario.dataFinalVigencia = this.datasDeVigencia[0] && this.datasDeVigencia[1]
				? new Date(this.datasDeVigencia[1] + ' 00:00:00.000') : null

			this.definirHoraParaData(this.configuracaoDeCupomDoFormulario.dataInicioVigencia, this.horas)
			this.definirHoraParaData(this.configuracaoDeCupomDoFormulario.dataFinalVigencia, this.horas)

			const cupomSalvo = !this.configuracaoDeCupomDoFormulario.id
				? await this.saveConfiguracaoDeCupomUseCase.create(this.configuracaoDeCupomDoFormulario)
				: await this.saveConfiguracaoDeCupomUseCase.update(this.configuracaoDeCupomDoFormulario)
			this.$emit('salvarCupom', cupomSalvo)
		
			AlertModule.setSuccess('Configuração de cupom de desconto salvo com sucesso')
		} catch (error) {
			this.carregando = false
			AlertModule.setError(error)
		} finally {
			this.carregando = false
			this.datasDeVigencia = []
			this.configuracaoDeCupomDoFormulario = criarConfiguracaoDeCupom()
			this.fecharDialogoDeEdicao()
		}
	}

	setPercentual(valor: boolean) {
		if(!this.configuracaoDeCupomDoFormulario) return
		this.configuracaoDeCupomDoFormulario.isPercentual = valor
	}

	fecharDialogoDeEdicao() {
		setTimeout(() => {
			this.formDeEdicaoDeConfiguracaoDeCupons.resetValidation()
		})
		this.mostraDialogo = false
	}

	
	get descontoEValido() {
		return () => {
			if (
				!this.configuracaoDeCupomDoFormulario.isPercentual &&
				this.configuracaoDeCupomDoFormulario.valorDoDesconto < 1
			)
				return 'Valor não pode ser menor ou igual a 0.'
			if (
				this.configuracaoDeCupomDoFormulario.isPercentual &&
				this.configuracaoDeCupomDoFormulario.valorDoDesconto < 1 &&
				this.configuracaoDeCupomDoFormulario.valorDoDesconto > 99.99
			)
				return 'Valor não pode ser maior que 99.99%'
			return true
		}
	}

}
